import {useEffect} from "react"
import mixpanel from "mixpanel-browser"

const CV_URL = "https://kimpers.s3.amazonaws.com/kim_persson_cv.pdf";

const CV = () => {
  useEffect(() => {
    try {
      mixpanel.init(
        "996efbe4e8f3d7d3cbd50e1a67074b0b",
        {api_host: "https://api-eu.mixpanel.com"},
        ""
      )

      const path = window && window.location && window.location.pathname
      mixpanel.track("Pageview", {path})
    } catch (err) {
      console.error(err)
    }

    window.location && window.location.replace(CV_URL)
  }, []);

  return null;
};

export default CV;
